.container{
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
}

.imageContainer {
    display: flex;
    flex:1;
    overflow-x: auto;
    padding-bottom: 10px;
}

.imageContainer div + div {
    margin-left: 20px;
}

.addCard {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 150px;
    border-radius: 10px;
    background: #eee;
    cursor: pointer;
    margin-right: 20px;
}

.imageCard {
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 150px;
    border-radius: 10px;
    cursor: pointer;
}

/*.imageCard:hover img{*/
/*    height: 170px;*/
/*}*/

/*.imageCard:hover ~ .buttonContainer {*/
/*    display: flex;*/
/*    height: 30px;*/
/*}*/

.buttonContainer span {
    display: none;
}

.buttonContainer:hover span {
    display: block;
    cursor: pointer;
}

